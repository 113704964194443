var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"page__table",attrs:{"headers":_vm.headers,"items":_vm.events,"loading":_vm.loading,"itemsPerPage":_vm.itemsPerPage,"item-key":"ID","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('custom-table-title',{attrs:{"onAdd":_vm.handleAdd,"title":("Actividades [" + (_vm.plan.id.toUpperCase()) + "]"),"icon":"mdi-ticket","onSearch":_vm.handleSearch}})]},proxy:true},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function () { _vm.handleEdit(item) }}},[_vm._v(_vm._s(_vm.getTranslation(item.Title, _vm.locale) || item.ID))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.plan.category ? _vm.plan.category.filter(function (x) { return x.id === item.CategoryID; }).map(function (item) { return item.name.es; }).shift() : '')+" ")]}},{key:"item.subcategory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.plan.subcategory ? _vm.plan.subcategory.filter(function (x) { return x.id === item.SubcategoryID; }).map(function (item) { return item.name.es; }).shift() : '')+" ")]}},{key:"item.weekday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.plan.weekday ? _vm.plan.weekday.filter(function (x) { return x.id === item.WeekdayID; }).map(function (item) { return item.name.es; }).shift() : '')+" ")]}}],null,true)}),_c('pagination',{attrs:{"startIndex":_vm.startIndex,"itemsPerPage":_vm.itemsPerPage,"count":_vm.count,"onGetData":_vm.handleGetData,"locale":_vm.locale}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }